#poemheader{
  font-style: italic;
}


body {
  margin: 0;
  font-family: "Oswald", "Quattrocento"

}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.349);
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: black;
  }

p {
  font-family: 'Quattrocento', serif;
  color: black;
}

.rotateRight {
  transform:rotate(90deg);
  -ms-transform:rotate(90deg); /* IE 9 */
  -moz-transform:rotate(90deg); /* Firefox */
  -webkit-transform:rotate(90deg); /* Safari and Chrome */
  -o-transform:rotate(90deg); /* Opera */
  }

  .rotateLeft {
    transform:rotate(270deg);
    -ms-transform:rotate(270deg); /* IE 9 */
    -moz-transform:rotate(270deg); /* Firefox */
    -webkit-transform:rotate(270deg); /* Safari and Chrome */
    -o-transform:rotate(270deg); /* Opera */
    }

.rotateTwice {
  transform:rotate(180deg);
  -ms-transform:rotate(180deg); /* IE 9 */
  -moz-transform:rotate(180deg); /* Firefox */
  -webkit-transform:rotate(180deg); /* Safari and Chrome */
  -o-transform:rotate(180deg); /* Opera */
}

@media(max-width: 1200px){

}

@media(max-width: 991px){
  
}

@media(min-width: 768px) and (max-width: 990px){
  .heart{
    display: none;
  }
  
}

@media(min-width: 500px) and (max-width: 768px){
  .Patti{
    display: none;
  }
  .flowerSpoon{
    display:none;
  }
  .heart{
    display: none;
  }
}

@media(max-width: 500px){
  .Patti{
    display: none;
  }
  .heart{
    display: none;
  }
}


ul.nav a:hover {
  color: black;
}

.navbar-toggler:focus {
  box-shadow: none;
  outline: none;
}

.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}

.dropdown-item:focus {
  box-shadow: none;
  outline: none;
  color:dimgrey;
}

a.dropdown-item.active {
  color:black;
  background-color: lightgray;
}


.nav-link.dropdown-toggle{
  color: red;
}

a.dropdown-item .click {
  color:black;
  background-color: lightgray;
}

.dropdown .dropdown-menu .dropdown-item:active {
  background-color: pink;
}
 
.recipe {
  font-family: 'Quattrocento', serif;
}

.gallery{
  color: black;
  text-decoration: none;
}

footer {
  position:inherit;
  height: 100px;
  bottom: 0;
  width: 100%;
  padding-bottom: 60px;
  font-size: smaller;
}

#crp{
  opacity: 0.5;
}